<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <slot />
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "LayoutLogin"
};
</script>
